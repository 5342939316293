<!--编辑可替代药品-->
<template>

  <el-dialog v-dialogDrag :title='batchInfo.breedName+"--可替代药品关联条件编辑"'
             width="60%" append-to-body :visible.sync="dialogVisible"
             @close="closeDialog"
             :close-on-click-modal="false"
  >
    <el-form ref="editForm" status-icon :model="editForm" label-width="140px"  v-loading="pageLoading">

      <el-form-item label="关联类型" prop="refType">
        <el-radio-group v-model="editForm.refType" size="mini" @change="refTypeChange">
          <el-radio-button :label="1" size="small">药物</el-radio-button>
          <el-radio-button :label="2" size="small">药物组</el-radio-button>
        </el-radio-group>
      </el-form-item>


      <el-form-item label="药物" prop="drugIdList" v-if="editForm.refType==1"
                    :rules="[{ required: true, message: '请选择药物', trigger: 'blur'}]"
      >
        <el-select style="width: 600px;"
                   :popper-append-to-body="false"
                   v-model="editForm.drugIdList"
                   filterable
                   remote
                   multiple
                   size="small"
                   clearable
                   reserve-keyword
                   placeholder="请输入关键字检索并选择药物"
                   :remote-method="searchDrug"
                   :loading="loading"
        >
          <i slot="prefix" class="el-icon-search"></i>
          <el-option
            v-for="item in drugOptions"
            :key="item.drugId"
            :label="item.drugName"
            :value="item.drugId"
          >
            <template>
              <el-tag style="float: left;width: 60px;" size="small">{{ drugClassifyDict['c' + item.classify] }}</el-tag>
              <span style="float: left;padding-left: 20px;">{{ item.drugName }}</span>
              <span style="float: right;font-size: 12px;color: #b4b4b4;">{{ item.alias }}</span>
            </template>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="药物组" prop="drugGroupIdList" v-if="editForm.refType==2"
                    :rules="[{ required: true, message: '请选择药物组', trigger: 'blur'}]"
      >
        <el-select style="width: 600px;"
                   :popper-append-to-body="false"
                   v-model="editForm.drugGroupIdList"
                   filterable
                   remote
                   multiple
                   size="small"
                   clearable
                   reserve-keyword
                   placeholder="请输入关键字检索并选择药物组"
                   :remote-method="searchDrugGroup"
                   :loading="loading"
        >
          <i slot="prefix" class="el-icon-search"></i>
          <el-option
            v-for="item in drugGroupOptions"
            :key="item.drugGroupId"
            :label="item.drugGroupName"
            :value="item.drugGroupId"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="集采剂型" prop="batchFormCodeList"
                    :rules="[{ required: true, message: '请选择集采剂型', trigger: 'blur'}]"
      >
        <el-select style="width: 600px;"
                   :popper-append-to-body="false"
                   v-model="editForm.batchFormCodeList"
                   filterable
                   multiple
                   size="small"
                   clearable
                   reserve-keyword
                   placeholder="请输入关键字检索并选择集采剂型"
                   :remote-method="searchDrug"
                   :loading="loading"
        >
          <i slot="prefix" class="el-icon-search"></i>
          <el-option
            v-for="item in batchFormOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="可替代类型" prop="type">
        <el-radio-group v-model="editForm.type" size="mini">
          <el-radio-button :label="1" size="small">完全可替代</el-radio-button>
          <el-radio-button :label="2" size="small">大部分可替代</el-radio-button>
          <el-radio-button :label="3" size="small">一定程度可替代</el-radio-button>
        </el-radio-group>
      </el-form-item>


    </el-form>
    <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="doSave" :disabled="pageLoading">确 定</el-button>
      </span>
  </el-dialog>


</template>

<script>
import { selectDrugNameList } from '@/api/dkm/drug'
import { selectDrugGroupNameList } from '@/api/dkm/drugGroup'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { getPurchaseBatchFormList, editCondition, addCondition, conditionInfo } from '@/api/dkm/purchaseBatchRecord'

export default {
  name: 'editReplaceDrug',
  props: {
    visible: { type: Boolean },
    conditionId: { type: Number },
    batchInfo: { type: Object, required: true }
  },
  watch: {
    visible: {
      deep: true,
      handler(newVal, oldVal) {
        this.dialogVisible = newVal
      }
    }
  },
  data() {
    return {
      conceptConst,
      dialogVisible: this.visible,
      loading: false,
      pageLoading:false,
      editForm: {
        refType: 2,
        type: 1,
        drugIdList: [],
        drugGroupIdList: [],
        batchFormCodeList: [],
        batchRecordId: this.batchInfo.batchRecordId,
        orderNo: this.batchInfo.orderNo,
        breedName: this.batchInfo.breedName
      },
      drugOptions: [],
      drugGroupOptions: [],
      drugClassifyDict: {},
      batchFormOptions: []
    }
  },
  created() {
    console.log('editReplaceDrug-created-batchInfo', this.batchInfo,this.conditionId)
    this.getDrugClassifyDict()
    this.queryPurchaseBatchFormList()
    if (this.conditionId) {
      this.pageLoading=true
      conditionInfo(this.conditionId).then(res => {
        this.editForm.refType = res.data.refType
        this.editForm.type = res.data.type
        this.editForm.id = res.data.id
        res.data?.drugList?.forEach(item => {
          this.editForm.drugIdList.push(item.drugId)
          this.drugOptions.push(item)
        })

        res.data?.drugGroupList?.forEach(item => {
          this.editForm.drugGroupIdList.push(item.drugGroupId)
          this.drugGroupOptions.push(item)
        })

        res.data.batchFormList.forEach(item => {
          this.editForm.batchFormCodeList.push(item.code)
        })
        this.pageLoading=false
      })
    }
  },
  methods: {
    //获取中西药物标识数据字典
    getDrugClassifyDict() {
      getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
        result.forEach(item => {
          this.drugClassifyDict['c' + item.conceptId] = item.conceptVal
        })
      })
    },
    // 远程搜索药物
    async searchDrug(query) {
      if (query !== '') {
        this.loading = true
        let res = await selectDrugNameList(query)
        if (res.code === 200) {
          this.loading = false
          res.data.forEach(item => {
            item.drugId = item.id
            item.drugName = item.name
            let index = item.dkbDrugAliasList.findIndex(s => {
              return s.alias === item.name
            })
            if (index > -1) {
              item.dkbDrugAliasList.splice(index, 1)
            }

            if (item.dkbDrugAliasList.length > 0) {
              item.alias = item.dkbDrugAliasList[0].alias
            }
          })
          this.drugOptions = res.data
        }
      } else {
        this.drugOptions = []
      }
    },
    // 远程搜索药物组
    async searchDrugGroup(query) {
      if (query !== '') {
        this.loading = true
        let res = await selectDrugGroupNameList({ queryStr: query })
        if (res.code === 200) {
          res.data.forEach(item => {
            item.drugGroupId = item.id
            item.drugGroupName = item.name
          })
          this.loading = false
          this.drugGroupOptions = res.data
        }
      } else {
        this.drugGroupOptions = []
      }
    },
    //获取集采剂型列表
    queryPurchaseBatchFormList() {
      getPurchaseBatchFormList().then(res => {
        this.batchFormOptions = res.data
      })
    },
    //关联类型变化，清除表单校验
    refTypeChange() {
      this.clearFormValidate('editForm')
    },
    //关闭弹窗
    closeDialog() {
      // 通知父组件，关闭弹窗
      this.dialogVisible = false
      this.$emit('update:visible', false)
    },
    //保存
    doSave() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          if (this.conditionId) {
            await editCondition(this.editForm)
          } else {
            await addCondition(this.editForm)
          }
          this.msgSuccess()
          this.closeDialog()
          this.$emit('refreshTable')
        } else {
          this.msgError('请规范填写')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
