<template>
  <div class="app-container" style="height: 100%;">
    <el-row style="height: 100%;">
      <el-col :span="8" style="height: 100%;border-right: 1px solid #ccc;padding-right: 6px;">
        <div style="height: 50px;line-height: 50px;padding-left:20px;">
          <el-input v-model="treeSearchVal" size="small" placeholder="" style="width:calc(100% - 120px);"></el-input>
          <el-button size="mini" style="margin-left: 10px;" @click="doRefresh()"
                     type="primary" icon="el-icon-refresh"
          >刷新
          </el-button>

        </div>
        <el-tree
          v-loading="treeLoading"
          style="height:calc(100% - 50px);overflow: auto"
          ref="drugTree"
          :data="drugTreeData"
          default-expand-all
          :highlight-current="true"
          :expand-on-click-node="false"
          :props="drugTreeProps"
          @node-click="handleNodeClick"
          :filter-node-method="filterTreeNode"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span v-if="data.orderNo=='0'">{{ data.breedName }}</span>
            <span v-if="data.orderNo!='0' && data.alternativeSize>0"
                  v-bind:style="{ color: data.selectFlag==0?'#f18181':'#606266'}"
            >
              {{ data.orderNo + '-' + data.breedName + '(' + data.alternativeSize + ')' }}
            </span>
            <span v-if="data.orderNo!='0' && data.alternativeSize==0"
                  v-bind:style="{ color: data.selectFlag==0?'#f18181':'#999999'}"
            >
              {{ data.orderNo + '-' + data.breedName }}
            </span>
          </div>
        </el-tree>
      </el-col>
      <el-col :span="16" style="height: 100%;padding-left: 6px;overflow: hidden;">
        <el-container style="height: 100%;">
          <el-header style="height: auto !important;min-height: 30px;"
                     v-show="this.tableQueryParams.orderNo!='0'"
          >
            <el-row>
              <el-col :span="14" id="purchaseBatchDrugTitle">
                <span style="margin-left: 20px;">
            {{ this.tableQueryParams.orderNo + '-' + this.tableQueryParams.breedName }}
          </span>
              </el-col>
              <el-col :span="10" v-show="this.tableQueryParams.selectFlag==1">
                <el-button icon="el-icon-circle-plus-outline" size="mini" style="margin-left: 20px;"
                           class="addClass" @click="addAlternative"
                >新增条件
                </el-button>
                <el-button icon="el-icon-refresh"
                           size="mini" class="addClass" @click="refreshRefPpByCondition"
                >数据同步
                </el-button>
              </el-col>
            </el-row>
          </el-header>
          <el-main style="padding: 10px;">
            <replace-drug-table :batch-info="tableQueryParams" v-if="this.tableQueryParams.orderNo!='0'"  @alternativeSizeChange="alternativeSizeChange"
            ></replace-drug-table>
            <div v-show="this.tableQueryParams.orderNo=='0'"
                 style="height: 100%;display: flex;justify-content: center;"
            >
              <el-empty description="请先选择一个药品">
              </el-empty>
            </div>
          </el-main>

        </el-container>
      </el-col>
    </el-row>

    <edit-replace-drug :visible.sync="addConditionDialogVisible" :batch-info="tableQueryParams"
                       v-if="addConditionDialogVisible" @refreshTable="doRefreshTable"
    ></edit-replace-drug>


  </div>
</template>

<script>
import { simpleRecordDrugTree,refreshRefPpByCondition } from '@/api/dkm/purchaseBatchRecord'
import editReplaceDrug from '@/views/dkm/purchaseBatch/components/editReplaceDrug'
import replaceDrugTable from '@/views/dkm/purchaseBatch/components/replaceDrugTable'
import { randomStr,isEmpty } from '@/utils/common'
export default {
  name: 'ReplaceDrugList',
  components: { editReplaceDrug, replaceDrugTable },
  data() {
    return {
      // 遮罩层
      treeLoading: false,
      treeSearchVal: '',
      // 招采批次id
      batchRecordId: undefined,
      drugTreeData: [],
      drugTreeProps: {
        children: 'children',
        label: 'breedName'
      },
      tableQueryParams: {
        orderNo: '0'
      },
      //新增关联条件弹窗
      addConditionDialogVisible: false

    }
  },
  watch: {
    // 监听输入框的值变化
    treeSearchVal(val) {
      this.treeLoading = true
      this.$refs.drugTree.filter(val)
      this.treeLoading = false
    }
  },
  created() {
    const batchRecordId = this.$route.params && this.$route.params.id
    this.batchRecordId = batchRecordId
    this.getDrugTreeData()

  },
  methods: {
    getDrugTreeData() {
      this.treeLoading = true
      simpleRecordDrugTree(this.batchRecordId).then(res => {
        this.drugTreeData = []
        this.drugTreeData.push(res.data)
        // this.drugTreeData=[
        //   {label: '一级 1',}
        // ]
        this.treeLoading = false
      })
    },
    /* 左侧树节点点击*/
    handleNodeClick(data, node) {
      this.tableQueryParams = data
      if (data.orderNo == '0') {
        this.msgInfo('请选择一个药品')
        return
      } else if (data.selectFlag == 0) {
        this.msgInfo('未中标药品，无需维护')
        return
      }
    },
    //树节点过滤
    filterTreeNode(value, data) {
      if (isEmpty(value)) {
        return true
      }
      return data.breedName && data.breedName.indexOf(value.trim()) !== -1
    },
    //点击刷新按钮
    doRefresh() {
      this.treeSearchVal = ''
      this.tableQueryParams.orderNo = '0'
      this.getDrugTreeData()

    },
    //新增关联按钮
    addAlternative() {
      this.addConditionDialogVisible = true
    },
    //根据条件刷新关联的制剂数据
    refreshRefPpByCondition() {
      refreshRefPpByCondition(this.tableQueryParams).then(res=>{
        this.msgSuccess("关联制剂数量:"+res.data)
        this.doRefreshTable()
      })
    },
    //表格刷新
    doRefreshTable(){
      //修改tableQueryParams触发表格组件刷新
      this.$set(this.tableQueryParams,'ss', randomStr())
    },
    //更新可替代药品数量
    alternativeSizeChange(size){
      this.tableQueryParams.alternativeSize=size
    }

  }

}
</script>

<style lang="scss" scoped>
.addClass{
  float: right;
}

</style>


<style>


</style>
