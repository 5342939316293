<!--可替代药品表格 （数据表格+条件表格）-->
<template>
  <el-row>
    <el-col :span="24" class="col-title">条件列表</el-col>
    <el-table
      border
      stripe
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      ref="drugTable"
      :data="conditionTableData"
      v-loading="conditionTableLoading"
    >
      <el-table-column type="index" min-width="50" label="序号"></el-table-column>
      <el-table-column min-width="150" label="集采剂型">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.batchFormList" size="mini" type="info" class="col-tag">{{ item.name }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column min-width="150" label="关联药物/药物组">
        <template slot-scope="scope">
          <template v-if=" scope.row.refType==1">
            <span>药物:</span>
            <el-tag v-for="item in scope.row.drugList" size="mini" class="col-tag">{{ item.drugName }}</el-tag>
          </template>
          <template v-if=" scope.row.refType==2">
            <span>药物组:</span>
            <el-tag v-for="item in scope.row.drugGroupList" size="mini" class="col-tag">{{ item.drugGroupName }}
            </el-tag>
          </template>
        </template>
      </el-table-column>


      <el-table-column width="150" label="可替代类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type==1" size="mini">完全可替代</el-tag>
          <el-tag v-if="scope.row.type==2" type="success" size="mini">大部分可替代</el-tag>
          <el-tag v-if="scope.row.type==3" type="info" size="mini">一定程度可替代</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="doEdit(scope.row)">编辑
          </el-button>
          <el-button type="text" size="small" @click="doDel(scope.row)" style="color: #F56C6C">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-col :span="24" class="col-title">关联制剂列表</el-col>
    <el-table
      border
      stripe
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      ref="drugTable"
      :default-sort="{prop: 'ppId', order: 'descending'}"
      :data="ppTableData"
      v-loading="ppTableLoading"
    >
      <el-table-column type="index" min-width="50" label="序号"></el-table-column>
      <el-table-column width="120" property="ppId" label="制剂ID" sortable></el-table-column>
      <el-table-column min-width="200" property="ppName" label="制剂名称" sortable></el-table-column>
      <el-table-column width="150" label="可替代类型" property="type" sortable>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type==1">完全可替代</el-tag>
          <el-tag v-if="scope.row.type==2" type="success">大部分可替代</el-tag>
          <el-tag v-if="scope.row.type==3" type="info">一定程度可替代</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <edit-replace-drug :visible.sync="editConditionDialogVisible" :batch-info="batchInfo"
                       :condition-id="editConditionId"
                       @refreshTable="refreshTable"
                       v-if="editConditionDialogVisible"
    ></edit-replace-drug>
  </el-row>


</template>

<script>
import { getAlternativeList, conditionList, delCondition } from '@/api/dkm/purchaseBatchRecord'
import editReplaceDrug from '@/views/dkm/purchaseBatch/components/editReplaceDrug'

export default {
  name: 'replaceDrugTable',
  components: { editReplaceDrug },
  props: {
    visible: { type: Boolean },
    batchInfo: { type: Object, required: true }
  },
  data() {
    return {
      conditionTableLoading: false,
      conditionTableData: [],
      //新增关联制剂弹窗
      editConditionDialogVisible: false,
      //当前编辑的条件ID
      editConditionId: '',
      ppTableData: [],
      ppTableLoading: []

    }
  },
  watch: {
    batchInfo: {
      deep: true,
      handler(newVal, oldVal) {
        console.log('batchInfo', newVal)
        this.refreshTable()
      }
    }
  },
  created() {
    this.refreshTable()
  },
  methods: {
    //查询条件列表
    queryConditionList() {
      this.conditionTableLoading = true
      conditionList(this.batchInfo).then(res => {
        this.conditionTableData = res.data
        this.conditionTableLoading = false

      })
    },
    //查询备选列表
    queryAlternativeList() {
      this.ppTableLoading = true
      getAlternativeList(this.batchInfo).then(res => {
        this.ppTableData = res.data
        this.ppTableLoading = false
        //更新可替代药品数量
        this.$emit('alternativeSizeChange',res.data.length)
      })
    },
    //点击编辑按钮
    doEdit(row) {
      this.editConditionDialogVisible = true
      this.editConditionId = row.id
    },
    //点击删除按钮
    doDel(row) {
      this.$confirm(`删除条件?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCondition(row.id).then(res => {
          this.msgSuccess()
          this.refreshTable()
        })
      }).catch(() => {

      })
    },
    refreshTable() {
      this.queryConditionList()
      this.queryAlternativeList()
    }
  }
}
</script>

<style scoped>
.col-tag {
  margin-left: 4px;
  margin-top: 4px;
}

.col-title {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  margin: 10px 0px;
}

</style>
