var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: _vm.batchInfo.breedName + "--可替代药品关联条件编辑",
        width: "60%",
        "append-to-body": "",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "editForm",
          attrs: {
            "status-icon": "",
            model: _vm.editForm,
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关联类型", prop: "refType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  on: { change: _vm.refTypeChange },
                  model: {
                    value: _vm.editForm.refType,
                    callback: function ($$v) {
                      _vm.$set(_vm.editForm, "refType", $$v)
                    },
                    expression: "editForm.refType",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: 1, size: "small" } },
                    [_vm._v("药物")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 2, size: "small" } },
                    [_vm._v("药物组")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.editForm.refType == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "药物",
                    prop: "drugIdList",
                    rules: [
                      {
                        required: true,
                        message: "请选择药物",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: {
                        "popper-append-to-body": false,
                        filterable: "",
                        remote: "",
                        multiple: "",
                        size: "small",
                        clearable: "",
                        "reserve-keyword": "",
                        placeholder: "请输入关键字检索并选择药物",
                        "remote-method": _vm.searchDrug,
                        loading: _vm.loading,
                      },
                      model: {
                        value: _vm.editForm.drugIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "drugIdList", $$v)
                        },
                        expression: "editForm.drugIdList",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                      _vm._l(_vm.drugOptions, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.drugId,
                            attrs: { label: item.drugName, value: item.drugId },
                          },
                          [
                            [
                              _c(
                                "el-tag",
                                {
                                  staticStyle: { float: "left", width: "60px" },
                                  attrs: { size: "small" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.drugClassifyDict["c" + item.classify]
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "padding-left": "20px",
                                  },
                                },
                                [_vm._v(_vm._s(item.drugName))]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "font-size": "12px",
                                    color: "#b4b4b4",
                                  },
                                },
                                [_vm._v(_vm._s(item.alias))]
                              ),
                            ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editForm.refType == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "药物组",
                    prop: "drugGroupIdList",
                    rules: [
                      {
                        required: true,
                        message: "请选择药物组",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: {
                        "popper-append-to-body": false,
                        filterable: "",
                        remote: "",
                        multiple: "",
                        size: "small",
                        clearable: "",
                        "reserve-keyword": "",
                        placeholder: "请输入关键字检索并选择药物组",
                        "remote-method": _vm.searchDrugGroup,
                        loading: _vm.loading,
                      },
                      model: {
                        value: _vm.editForm.drugGroupIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "drugGroupIdList", $$v)
                        },
                        expression: "editForm.drugGroupIdList",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                      _vm._l(_vm.drugGroupOptions, function (item) {
                        return _c("el-option", {
                          key: item.drugGroupId,
                          attrs: {
                            label: item.drugGroupName,
                            value: item.drugGroupId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "集采剂型",
                prop: "batchFormCodeList",
                rules: [
                  {
                    required: true,
                    message: "请选择集采剂型",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "600px" },
                  attrs: {
                    "popper-append-to-body": false,
                    filterable: "",
                    multiple: "",
                    size: "small",
                    clearable: "",
                    "reserve-keyword": "",
                    placeholder: "请输入关键字检索并选择集采剂型",
                    "remote-method": _vm.searchDrug,
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.editForm.batchFormCodeList,
                    callback: function ($$v) {
                      _vm.$set(_vm.editForm, "batchFormCodeList", $$v)
                    },
                    expression: "editForm.batchFormCodeList",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                  _vm._l(_vm.batchFormOptions, function (item) {
                    return _c("el-option", {
                      key: item.code,
                      attrs: { label: item.name, value: item.code },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可替代类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.editForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.editForm, "type", $$v)
                    },
                    expression: "editForm.type",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: 1, size: "small" } },
                    [_vm._v("完全可替代")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 2, size: "small" } },
                    [_vm._v("大部分可替代")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 3, size: "small" } },
                    [_vm._v("一定程度可替代")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-right": "20px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.pageLoading },
              on: { click: _vm.doSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }