var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-row",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                height: "100%",
                "border-right": "1px solid #ccc",
                "padding-right": "6px",
              },
              attrs: { span: 8 },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "50px",
                    "line-height": "50px",
                    "padding-left": "20px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "calc(100% - 120px)" },
                    attrs: { size: "small", placeholder: "" },
                    model: {
                      value: _vm.treeSearchVal,
                      callback: function ($$v) {
                        _vm.treeSearchVal = $$v
                      },
                      expression: "treeSearchVal",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-refresh",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doRefresh()
                        },
                      },
                    },
                    [_vm._v("刷新 ")]
                  ),
                ],
                1
              ),
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeLoading,
                    expression: "treeLoading",
                  },
                ],
                ref: "drugTree",
                staticStyle: { height: "calc(100% - 50px)", overflow: "auto" },
                attrs: {
                  data: _vm.drugTreeData,
                  "default-expand-all": "",
                  "highlight-current": true,
                  "expand-on-click-node": false,
                  props: _vm.drugTreeProps,
                  "filter-node-method": _vm.filterTreeNode,
                },
                on: { "node-click": _vm.handleNodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("div", { staticClass: "custom-tree-node" }, [
                        data.orderNo == "0"
                          ? _c("span", [_vm._v(_vm._s(data.breedName))])
                          : _vm._e(),
                        data.orderNo != "0" && data.alternativeSize > 0
                          ? _c(
                              "span",
                              {
                                style: {
                                  color:
                                    data.selectFlag == 0
                                      ? "#f18181"
                                      : "#606266",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.orderNo +
                                        "-" +
                                        data.breedName +
                                        "(" +
                                        data.alternativeSize +
                                        ")"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        data.orderNo != "0" && data.alternativeSize == 0
                          ? _c(
                              "span",
                              {
                                style: {
                                  color:
                                    data.selectFlag == 0
                                      ? "#f18181"
                                      : "#999999",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.orderNo + "-" + data.breedName
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                height: "100%",
                "padding-left": "6px",
                overflow: "hidden",
              },
              attrs: { span: 16 },
            },
            [
              _c(
                "el-container",
                { staticStyle: { height: "100%" } },
                [
                  _c(
                    "el-header",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: this.tableQueryParams.orderNo != "0",
                          expression: "this.tableQueryParams.orderNo!='0'",
                        },
                      ],
                      staticStyle: {
                        height: "auto !important",
                        "min-height": "30px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { span: 14, id: "purchaseBatchDrugTitle" },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.tableQueryParams.orderNo +
                                          "-" +
                                          this.tableQueryParams.breedName
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: this.tableQueryParams.selectFlag == 1,
                                  expression:
                                    "this.tableQueryParams.selectFlag==1",
                                },
                              ],
                              attrs: { span: 10 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: { click: _vm.addAlternative },
                                },
                                [_vm._v("新增条件 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.refreshRefPpByCondition },
                                },
                                [_vm._v("数据同步 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { padding: "10px" } },
                    [
                      this.tableQueryParams.orderNo != "0"
                        ? _c("replace-drug-table", {
                            attrs: { "batch-info": _vm.tableQueryParams },
                            on: {
                              alternativeSizeChange: _vm.alternativeSizeChange,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.tableQueryParams.orderNo == "0",
                              expression: "this.tableQueryParams.orderNo=='0'",
                            },
                          ],
                          staticStyle: {
                            height: "100%",
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("el-empty", {
                            attrs: { description: "请先选择一个药品" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addConditionDialogVisible
        ? _c("edit-replace-drug", {
            attrs: {
              visible: _vm.addConditionDialogVisible,
              "batch-info": _vm.tableQueryParams,
            },
            on: {
              "update:visible": function ($event) {
                _vm.addConditionDialogVisible = $event
              },
              refreshTable: _vm.doRefreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }